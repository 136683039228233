<template>
  <gallery source="posters" />
</template>

<script>

export default {
  name: 'Posters',
  components: {
    Gallery: () => import('../apps/gallery/components/Gallery.vue')
  },
  data () {
    return {
    }
  }
}
</script>
